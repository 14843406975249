export default class ResetPasswordViewModel {
  readonly STEPS = ['NewPassword', 'PasswordChanged'];

  step_index = 0;

  get current_step() {
    return this.STEPS[this.step_index];
  }

  nextStep = () => {
    this.step_index += 1;
  };

  prevStep = () => {
    this.step_index -= 1;
  };
}
