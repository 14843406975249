














import { Component, Vue } from 'vue-property-decorator';
import ResetPasswordViewModel from '@/vue-app/view-models/components/on-boarding/reset-password/reset-password-view-model';

@Component({
  name: 'ResetPassword',
  components: {
    NewPassword: () => import('@/vue-app/components/onboarding/reset-password/NewPassword.vue'),
    PasswordChanged: () => import('@/vue-app/components/onboarding/reset-password/PasswordChanged.vue'),
  },
})
export default class ResetPassword extends Vue {
  readonly reset_password_view_model = Vue.observable(new ResetPasswordViewModel());
}
